<script setup lang="ts">
import DialogSwitchRedeemLocation from '~/components/dialog/DialogSwitchRedeemLocation.vue';
import DialogSwitchAccount from '~/components/dialog/DialogSwitchAccount.vue';
import DialogSwitchSalesChannel from '~/components/dialog/DialogSwitchSalesChannel.vue';
import { homePath } from '~/utils/routes';
import terminalRedeemRoutes from '~/utils/terminalRedeemRoutes';

/* Pinia stores */

/**
 * The auth Pinia store.
 */
const authPinia = useAuthStore();

/**
 * The terminal Pinia store.
 */
const terminalStore = useTerminalStore();

/* Component variables */

/**
 * The sidebar element.
 */
const sidebar: Ref<HTMLDivElement | null> = ref(null);

/**
 * The show sidebar button element.
 */
const showSidebarButton: Ref<HTMLButtonElement | null> = ref(null);

/**
 * Is the sidebar open.
 */
const sidebarOpen: Ref<boolean> = ref(false);

/**
 * The switch redeem location dialog.
 */
const switchRedeemLocationDialog: Ref<
  typeof DialogSwitchRedeemLocation | null
> = ref(null);

/**
 * The switch account dialog.
 */
const switchAccountDialog: Ref<typeof DialogSwitchAccount | null> = ref(null);

/**
 * The switch sales channel dialog.
 */
const switchSalesChannelDialog: Ref<typeof DialogSwitchSalesChannel | null> =
  ref(null);

/* Computed variables */

/**
 * The basket type.
 */
const type: ComputedRef<'test' | 'live' | null> = computed(
  (): 'test' | 'live' | null => {
    if (useRoute().path === '/terminal') {
      return null;
    }

    return useRoute().params.type.toString() as 'test' | 'live';
  },
);

/**
 * The current partner.
 */
const partner: ComputedRef<Partner> = computed(
  (): Partner => useCommonStore().partner,
);

/**
 * The currently authenticated user.
 */
const user: ComputedRef<AuthUser | null> = computed(
  (): AuthUser | null => authPinia.user,
);

/**
 * Determines what nav selector type to show.
 *
 */
const navbarSelectorType: ComputedRef<
  null | 'redeemLocationDialogButton' | 'salesChannelLink' | 'div'
> = computed(
  (): null | 'redeemLocationDialogButton' | 'salesChannelLink' | 'div' => {
    // If no user is set
    if (!user.value) {
      // Return null
      return null;
    }

    // Extract the route
    const route = useRoute().matched[0].path;

    // If the route is an ambiguous terminal route
    if (terminalRoutes.includes(route)) {
      return null;
    }

    // If the route is a terminal redeem route
    if (terminalRedeemRoutes.includes(route)) {
      // And the user has access to multiple redeem locations
      if ((user.value as AuthUser).redeem_locations.length > 1) {
        return 'redeemLocationDialogButton';
      }
    }

    // If the route is a terminal sell route
    if (terminalSellRoutes.includes(route)) {
      return 'salesChannelLink';
    }

    // Otherwise return div
    return 'div';
  },
);

/**
 * Determines what nav selector model to show.
 */
const navbarSelectorModel: ComputedRef<SalesChannel | RedeemLocation | null> =
  computed((): SalesChannel | RedeemLocation | null => {
    // If no user is set
    if (!user.value) {
      // Return null
      return null;
    }

    // Extract the route
    const route = useRoute().matched[0].path;

    // If the route is a terminal redeem route
    if (terminalRedeemRoutes.includes(route)) {
      return terminalStore.redeemLocation as RedeemLocation;
    }

    // If the route is a terminal sell route
    if (terminalSellRoutes.includes(route)) {
      return terminalStore.salesChannel as SalesChannel;
    }

    // Otherwise return null
    return null;
  });

/* Component methods */

/**
 * Listen for a click event and close the sidebar on trigger.
 */
const listenForClick = (event: Event): void => {
  // If the sidebar is set (needed to handle events triggering before the button is mounted)
  if (sidebar.value) {
    const target = event.target as Element;
  }

  // If the open sidebar button is set (needed to handle events triggering before the button is
  // mounted)
  if (showSidebarButton.value) {
    const target = event.target as Element;

    // If the event is inside the sidebar
    if (showSidebarButton.value.contains(target)) {
      // Ignore the event
      return;
    }
  }

  // If the sidebar is open
  if (sidebar.value) {
    // Close the menu
    hideSidebar();
  }
};

/**
 * Show the sidebar.
 */
const showSidebar = (): void => {
  sidebarOpen.value = true;

  // Add the event listener to listen for clicks outside the sidebar
  document.addEventListener('click', listenForClick);
};

/**
 * Close the sidebar.
 */
const hideSidebar = (): void => {
  sidebarOpen.value = false;

  // Remove the event listener to listen for clicks outside the sidebar
  document.removeEventListener('click', listenForClick);
};

/**
 * Open the switch redeem location dialog.
 */
const openSwitchRedeemLocationDialog = (): void => {
  (
    switchRedeemLocationDialog.value as typeof DialogSwitchRedeemLocation
  ).showDialog();
};

/**
 * Open the switch account dialog.
 */
const openSwitchAccountDialog = (): void => {
  (switchAccountDialog.value as typeof DialogSwitchAccount).showDialog();
};

const switchSalesChannel = async (): Promise<void> => {
  if (!terminalStore.basket) {
    await navigateTo(`/terminal`);
    return;
  }

  (
    switchSalesChannelDialog.value as typeof DialogSwitchSalesChannel
  ).showDialog();
};
</script>

<template>
  <template v-if="user">
    <div
      id="navbar"
      class="sticky top-0 z-10 flex h-[3.75rem] shrink-0 items-center justify-between gap-5 bg-white pl-3 pr-5 md:h-20 md:pl-6 md:pr-8"
    >
      <div class="flex shrink-0 items-center gap-3">
        <button
          type="button"
          id="terminal-sidebar-button"
          class="flex h-10 items-center px-2"
          ref="showSidebarButton"
          @click="showSidebar"
        >
          <SvgMenu />
        </button>

        <template v-if="partner.logo_url">
          <img
            class="size-6 min-[576px]:hidden"
            :src="partner.square_logo_url"
            :alt="partner.name + ' logo'"
          />

          <img
            class="hidden h-6 min-[576px]:block md:h-8"
            :src="partner.logo_url"
            :alt="partner.name + ' logo'"
          />
        </template>
      </div>

      <button
        v-if="navbarSelectorType === 'redeemLocationDialogButton'"
        id="navbar-selector"
        class="flex items-center gap-2 overflow-hidden rounded-lg px-3 py-2"
        style="background-color: var(--grey-800)"
        @click="openSwitchRedeemLocationDialog"
      >
        <SvgTerminalSelectorCircleArrow
          class="size-[1.125rem] flex-shrink-0 fill-none"
        />

        <SvgSalesChannel class="size-[1.125rem] flex-shrink-0 fill-none" />

        <span class="help-text-strong truncate">{{
          navbarSelectorModel?.name
        }}</span>
      </button>

      <template v-if="navbarSelectorModel">
        <button
          v-if="navbarSelectorType === 'salesChannelLink'"
          @click="switchSalesChannel"
          id="navbar-selector"
          class="flex items-center gap-2 overflow-hidden rounded-lg px-3 py-2"
          style="background-color: var(--grey-800)"
        >
          <SvgTerminalSelectorCircleArrow
            class="size-[1.125rem] flex-shrink-0 fill-none"
          />

          <SvgSalesChannel class="size-[1.125rem] flex-shrink-0 fill-none" />

          <span class="help-text-strong truncate">{{
            navbarSelectorModel?.name
          }}</span>
        </button>

        <div
          v-else-if="navbarSelectorType === 'div'"
          id="navbar-selector"
          class="flex items-center gap-2 overflow-hidden rounded-lg px-3 py-2"
          style="background-color: var(--grey-800)"
        >
          <SvgSalesChannel class="size-[1.125rem] flex-shrink-0 fill-none" />

          <span class="help-text-strong truncate">{{
            navbarSelectorModel?.name
          }}</span>
        </div>
      </template>
    </div>

    <div v-if="sidebarOpen" id="terminal-sidebar" ref="sidebar" class="z-50">
      <div id="terminal-sidebar-links">
        <button
          class="button button-no-style terminal-sidebar-close-button"
          @click="hideSidebar"
        >
          <SvgClose />

          Close
        </button>

        <hr />

        <NuxtLink
          v-if="authPinia.role === 'admin'"
          :to="homePath()"
          class="terminal-sidebar-link"
        >
          Back to dashboard
        </NuxtLink>

        <button
          v-if="(user as AuthUser).email"
          type="button"
          class="button button-no-style terminal-sidebar-link"
          @click="openSwitchAccountDialog"
        >
          Switch account
        </button>

        <NuxtLink to="/terminal/auth/settings" class="terminal-sidebar-link">
          User settings
        </NuxtLink>

        <button
          type="button"
          class="button button-no-style terminal-sidebar-link terminal-sidebar-button-danger"
          @click="authPinia.logout"
        >
          Log out
        </button>
      </div>

      <div id="user-container">
        <SvgUser />

        <span id="username">
          {{ (user as AuthUser).email ?? (user as AuthUser).username }}
        </span>
      </div>
    </div>

    <DialogSwitchRedeemLocation
      v-if="navbarSelectorType === 'redeemLocationDialogButton'"
      ref="switchRedeemLocationDialog"
    />
    <DialogSwitchAccount
      v-if="user"
      ref="switchAccountDialog"
      redirect-url="/terminal/redeem"
    />
    <DialogSwitchSalesChannel
      v-if="terminalStore.basket"
      ref="switchSalesChannelDialog"
    />
  </template>
</template>

<style scoped>
#terminal-sidebar {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 20rem;
  height: 100%;
  padding: 2rem 3.75rem 2.5rem;
  background: #fff;
}

#terminal-sidebar-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.terminal-sidebar-close-button {
  display: flex;
  height: 2.75rem;
  color: var(--grey-500);
  font-size: 0.875rem;
  font-weight: 600;
  gap: 0.625rem;
}

.terminal-sidebar-link {
  display: flex;
  align-items: center;
  height: 2.75rem;
  color: var(--grey-500);
  font-size: 0.875rem;
  font-weight: 500;
}

.terminal-sidebar-link:hover {
  color: var(--grey-400);
}

.terminal-sidebar-button-danger {
  color: var(--danger-600);
}

.terminal-sidebar-button-danger:hover {
  color: var(--danger-500);
}

#user-container {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 2.5rem;
  color: var(--grey-500);
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0125rem;
  line-height: 1.5rem;
  gap: 1.5rem;
}

/* 768px */
@media screen and (width >= 48em) {
  #navbar {
    height: 5rem;
    padding: 0.625rem 2rem;
    gap: 2rem;
  }

  #navbar-logo {
    height: 2rem;
  }
}
</style>
